<template>
  <modal-card :title="`File backup logs`" :processing="processing">
    <div>
      <pre v-if="hasLogs">{{ notes }}</pre>

      <no-results
        v-else
        title="No logs"
        message="There are no logs to show."
        style="margin: auto;"
      />
    </div>
  </modal-card>
</template>

<script>
export default {
  name: "FileBackupsNotesModal",
  props: {
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      processing: false
    };
  },
  computed: {
    hasLogs() {
      return !this.$_.isEmpty(this.config.backupnotes);
    },
    notes() {
      if (!this.hasLogs) return [];
      try {
        return JSON.parse(this.config.backupnotes);
      } catch {
        return this.config.backupnotes;
      }
    }
  }
};
</script>
